
export default {
  loadHotelAbTests() {
    if(document.querySelector('#hotelAbTest')){
      document.querySelector('#hotelAbTest').innerText = document.querySelector('#hotelABTestStatement').innerText
    }
  },

  loadF1PackageAbTests() {
    if(document.querySelector('#f1ABTestStatement')){
      document.querySelector('#packageForm .pricing_header').firstChild.textContent = document.querySelector('#f1ABTestStatement').innerText
    }
  }
}