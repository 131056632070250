import ComparisonBox from '../classes/comparison_box'
import ComparisonButton from '../classes/comparison_button'
import VueLoader from '../default/vue-loader'
// import CurrencyDropdown from '../shared/currency_dropdowns'
import CurrencyDropdown from '../default/currency_convert/currency-dropdown.vue'

import { store } from '../default/store'

document.addEventListener('turbo:load', () => {
  let comparisonButtons = document.querySelectorAll('.compare-package-button')
  if(comparisonButtons.length == 0) { return }


  let box_objs = Array.from(document.querySelectorAll('.comparison-box')).filter(function(box) {
    return window.getComputedStyle(box).display !== 'none'
  }).map(box => new ComparisonBox(box))
  Array.from(comparisonButtons).map (button => {
    let button_ojb = new ComparisonButton(button.dataset.id, button.dataset.name, button)
    if(button.classList.contains('chosen')) {
      fillBoxByButton(box_objs, button_ojb)
    }
    button.addEventListener('click', () => {
      fillBoxByButton(box_objs, button_ojb)
    })
  })
  document.getElementById('compareContinueButton').addEventListener('click', () => {
    let ids = box_objs.filter(box => box.occupied).map(box => box.id)
    window.location.href = `/comparisons?ids=${ids.join(',')}`
  })
})

document.addEventListener('turbo:load', () => {
  if (!document.getElementById('packageComparisonPage')) { return }
  setDropdowns()
  setXButtons()
})

function fillBoxByButton(box_objs, button_ojb){
  displayComparisonBox()
  let occupied = box_objs.find(box => box.id == button_ojb.id)
  if(occupied) {
    vacate(occupied, box_objs)
  } else {
    fillBox(box_objs, button_ojb)
    displayContinueButton(box_objs)
    updateCount(box_objs)
  }
}

function vacate(occupied, box_objs){
  occupied.vacate(box_objs)
}

function setXButtons() {
  let xButtons = Array.from(document.querySelectorAll('#PackageNamesRow .close'))
  let xButtonIds = xButtons.map(button => button.dataset.id)
  if(xButtons.length == 1 && document.getElementById('backToEvent')) {
    xButtons[0].href = document.getElementById('backToEvent').href
  } else {
    xButtons.forEach((button) => {
      let ids = xButtonIds.filter(item => item !== button.dataset.id)
      button.href = `/comparisons?ids=${ids.join(',')}`
    })
  }
}

function setDropdowns() {
  let dropdowns = null

  dropdowns = document.querySelectorAll('.currency-dropdown')
  // CurrencyDropdown.loadCurrencyDropdown()
  for (let dropdown of dropdowns) {
    VueLoader.loadVueComponent(CurrencyDropdown, dropdown, store)
  }
}

function fillBox(box_objs, button_ojb) {
  let firstOpenBox = box_objs.find(box => !box.occupied)
  firstOpenBox.occupy(button_ojb.id, button_ojb.name, box_objs, button_ojb)
}

function displayComparisonBox() {
  let overlay = document.querySelector('#packageComparisonOverlay')
  if(overlay.classList.contains('inactive')) {
    overlay.classList.remove('inactive')
  }
}

function displayContinueButton(box_objs) {
  let button = document.querySelector('#compareContinueButton')
  if(button.classList.contains('inactive')&& box_objs.filter(box => box.id ).length >= 2) {
    button.classList.remove('inactive')
  }
}

function updateCount(box_objs){
  document.querySelector('#chosenAmount').innerText = box_objs.filter(box => box.id ).length
}