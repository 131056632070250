
export default class ComparisonBox {
  constructor(element) {
    this.id = null
    this.name = null
    this.occupied = false
    this.associatedButton = null
    this.element = element
  }

  occupy = (id, name, box_objs, associatedButton) => {
    this.id = id
    this.name = name
    this.occupied = true
    this.associatedButton = associatedButton

    this.element.querySelector('.package-name').innerText = this.name
    this.enableExit(box_objs)
    this.element.dataset.id = this.id
    this.associatedButton.element.classList.add('chosen')
  }

  vacate = (box_objs) => {
    this.id = null
    this.name = null
    this.occupied = false
    this.element.querySelector('.package-name').innerText = null
    this.element.querySelector('.circle').classList.remove('active')
    this.element.dataset.id = null
    this.hideContinueButton(box_objs)
    this.hideOverlay(box_objs)
    this.updateCount(box_objs)
    this.associatedButton.element.classList.remove('chosen')
  }

  enableExit = (box_objs) => {
    let exit = this.element.querySelector('.circle')
    exit.classList.add('active')
    exit.addEventListener('click', () => {
      this.vacate(box_objs)
    })
  }

  hideContinueButton = (box_objs) => {
    let button = document.querySelector('#compareContinueButton')
    if(!button.classList.contains('inactive')&& box_objs.filter(box => box.id ).length < 2) {
      button.classList.add('inactive')
    }
  }
  hideOverlay = (box_objs) => {

    if(box_objs.filter(box => box.id ).length == 0) {
      let overlay = document.querySelector('#packageComparisonOverlay')

      overlay.classList.add('inactive')
    }
  }

  updateCount = (box_objs) => {
    document.querySelector('#chosenAmount').innerText = box_objs.filter(box => box.id ).length
  }
}
