$(document).on('turbo:load', function () {
  $('#hide_map_f1_mobile').on('click', () => {
    $('.seating_chart .map').toggleClass('hide-map')
    $('.seating_chart .sorting-wrapper').toggleClass('hide-map')
    let mapButton = document.querySelector('.seating_chart #hide_map_f1_mobile')
    if (!mapButton) return
    let buttonTextNode = mapButton.childNodes[mapButton.childNodes.length - 1]
    if (buttonTextNode && buttonTextNode.nodeType === Node.TEXT_NODE) {
      let currentText = buttonTextNode.textContent
      let icon = document.querySelector('.seating_chart #hide_map_f1_mobile i')
      if (icon) {
        icon.classList.toggle('fa-eye-slash')
        icon.classList.toggle('fa-eye')
      }
      if (currentText === ' Show Map') {
        buttonTextNode.textContent = ' Hide Map'
      } else {
        buttonTextNode.textContent = ' Show Map'
      }
    }
  })
})
