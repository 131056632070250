
document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.navbar-dropdown .dropdown-menu').forEach((dropdownMenu) => {
    dropdownMenu.addEventListener('mouseover', () => {
      let menuItem = dropdownMenu.parentNode
      menuItem.classList.add('active')
    })

    dropdownMenu.addEventListener('mouseout', () => {
      let menuItem = dropdownMenu.parentNode
      menuItem.classList.remove('active')
    })
  })
})
