import FastClick from 'fastclick'
import {sizeNavbar} from './navbar_functions'

// let navbarHeight = undefined

const isMobileNav = () => $('.check-for-mobile').css('float') === 'none'
// const isCollapsible = () => $('main').height() > $(window).height()
const isCollapsed = () => isMobileNav() || $('body').hasClass('navbar-collapsed')
const mobileNavIsVisible = () => isMobileNav() && $('.navbar-collapse-md').hasClass('in')
// const motoGp = window.brand_name == 'MotoGP™ Premier'
// const livGolf = window.brand_name == 'LIV Golf'
// const mse = window.brand_name == 'mse'
// const nascar = window.brand_name == 'NASCAR Chicago Experiences'

// function updateCollapse() {
//   debugger
//   if (!navbarHeight || !isCollapsible() || motoGp || livGolf || mse || nascar) { return }
//   debugger
//   const body = $('body')
//   const collapsed = body.hasClass('navbar-collapsed')
//   const toggle = (collapsed && (window.pageYOffset < navbarHeight)) ||
//     (!collapsed && (window.pageYOffset >= navbarHeight))

//   if (toggle) { body.toggleClass('navbar-collapsed') }
//   sizeNavbar()
// }



$(document)
  .on('turbo:load', function() {
    $('.navbar-collapse-md').removeClass('in')
    $('.navbar-toggle').addClass('collapsed')
    FastClick.attach(document.body)
    // if ($('.navbar').is(':visible')) { navbarHeight = $('.navbar').height() }
    sizeNavbar()
  })

  .on('hide.bs.dropdown', '.navbar-nav', function(event) {
    if (isCollapsed()) {
      event.stopPropagation()
      event.preventDefault()
    }
  })

  .on('show.bs.dropdown', '.navbar-nav', function(event) {
    if (isCollapsed()) {
      $(event.target).siblings().toggleClass('child-expanded')
    }
  })

  .on('click', '.navbar-nav .dropdown.open > .dropdown-toggle', function(event) {
    if (isCollapsed()) {
      let parent = $(event.currentTarget).parent()
      parent.removeClass('open').siblings().toggleClass('child-expanded')
      event.stopPropagation()
      event.preventDefault()
    }
  })

  .on('click', '#search-button', () => $('#search').focus())

  .on('scroll click turbo:load', function(event) {
    if (isMobileNav()) return
    if (!$.contains($('.navbar-collapse'), event.target)) {
      $('.navbar-collapse').collapse('hide')
      $('.navbar .open:not(.search-dropdown)').removeClass('open')
    }
  })

  .on('click', '.navbar-short .navbar-toggle', () => {
    $('body').toggleClass('scroll-lock')
  })
    
// .on('scroll', updateCollapse)

  .on('hidden.bs.collapse', '.navbar-collapse', function() {
    $('.navbar .child-expanded').removeClass('child-expanded')
    $('.navbar .open').removeClass('open')
  })

  .on('click', '.icon-language', function() {
    $('.navbar-collapse-md').removeClass('in')
    $('.navbar-toggle').addClass('collapsed')
  })

window.addEventListener('resize', () => {
  mobileNavIsVisible() ? $('body').addClass('scroll-lock') : $('body').removeClass('scroll-lock')
  sizeNavbar()
})
