const tabFunction = function() {
// tabbed content
  // http://www.entheosweb.com/tutorials/css/tabs.asp
  $('.tab_content').hide()
  $('.tab_content:first-of-type').show()

  /* if in tab mode */
  $('ul.tabs li').click(function() {
    var scopedTab = $(this).closest('.tab-pane')
    scopedTab.find('.tab_content').hide()
    var activeTab = $(this).attr('rel')
    $('#'+activeTab).fadeIn()
    scopedTab.find('ul.tabs li').removeClass('active')
    $(this).addClass('active')

    scopedTab.find('.tab_drawer_heading').removeClass('d_active')
    scopedTab.find('.tab_drawer_heading[rel^=\''+activeTab+'\']').addClass('d_active')

    /*$(".tabs").css("margin-top", function(){
      return ($(".tab_container").outerHeight() - $(".tabs").outerHeight() ) / 2;
   });*/
  })
  $('.tab_container').css('min-height', function(){
    return $('.tabs').outerHeight() + 50
  })
  /* if in drawer mode */
  $('.tab_drawer_heading').click(function() {
    var scopedTab = $(this).closest('.tab-pane')

    var closingCurrentTab = $(this).hasClass('d_active')

    scopedTab.find('.tab_content').hide()
    scopedTab.find('.tab_drawer_heading').removeClass('d_active')
    scopedTab.find('ul.tabs li').removeClass('active')

    if (!closingCurrentTab) {
      var d_activeTab = $(this).attr('rel')
      $('#'+d_activeTab).fadeIn()
      $(this).addClass('d_active')
      $('ul.tabs li[rel^=\''+d_activeTab+'\']').addClass('active')
    }
  })
}

export default {
  loadTabs(windowSize) {
    let content = $('.tabs .active').attr('rel') || $('.tabs li:first-of-type').attr('rel')
    if (content == undefined) { return }

    tabFunction()

    if ($(window).width() < windowSize) {
      $('.tab_drawer_heading').removeClass('d_active')
      $('.tab_content').css('display','none')
      $('.tabs_wrapper .tabs').css('display', 'none')
    }

    $(window).on('resize', function(){
      if ($(window).width() < windowSize) {
        $('.tab_drawer_heading').removeClass('d_active')
        $('.tab_content').css('display','none')
        $('.tabs_wrapper .tabs').css('display', 'none')
      } else {
        $('.tab_drawer_heading').addClass('d_active')
        $('.tabs_wrapper .tabs').css('display','inline-block')
        $(`#${content}.tab_content`).css('display','block')
      }
    })
  }
}
